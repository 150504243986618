import React from 'react';
import {observer} from 'mobx-react';
import { useStores } from '../../hooks/use-store'
import Warning from "@material-ui/core/SvgIcon/SvgIcon";
import SnackbarContent from "../Snackbar/SnackbarContent";
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.js';

const Container = ({children, ...rest}) => {

    const { CommonStore: {alert, loadingSpinnerVisible} } = useStores();

    return (
        <div style={{textAlign: 'center'}}>
            {children}
        </div>
    )
};

export default observer(Container);
