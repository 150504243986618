import React from "react";
import {makeStyles, Grid} from "@material-ui/core";

import styles from "../assets/jss/material-kit-react/views/textPage.js";
import Container from "../components/CustomContainer/Container";
import Header from "../components/Header/Header";
import HeaderLinks from "../components/Header/HeaderLinks";
import Parallax from "../components/Parallax/Parallax";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";

import classNames from "classnames";

import Footer from "../components/Footer/Footer";

const useStyles = makeStyles(styles);

function Api({...rest}) {
    const classes = useStyles();

    return (
        <Container>
            <Header
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 100,
                    color: "white"
                }}
                brand="Orto"
                rightLinks={<HeaderLinks />}
                {...rest}
            />
            <Parallax filter small image={require("../assets/img/header-background.jpg")}>
                <div className={classes.container + ' ' + classes.textCenter}>
                    <GridContainer>
                        <GridItem>
                            <h2 className={classes.textCenter}>Data Services</h2>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>

            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                <GridContainer spacing={1}>
                    <Grid item md={9} lg={9} xs={12}>
                            <h2 className={classes.title}>Data Services</h2>

                        <p>We can provide access to our data API for commercial use at a fixed monthly price.</p>
                    </Grid>
                </GridContainer>
                </div>
            </div>
            <Footer />
        </Container>
    )
}

export default Api;
