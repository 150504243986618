import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from "history";

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import MyVehicles from "./views/MyVehicles/MyVehicles";
import Homepage from "./views/Homepage";
import LoginPage from './views/SignIn.js';
import Api from './views/Api.js';
import Admin from './views/admin.js'
import Privacy from './views/Privacy';
import Terms from './views/Terms.js';
import FAQ from './views/FAQ.js';

import "./assets/scss/material-kit-react.scss?v=1.8.0";

const hist = createBrowserHistory();

ReactDOM.render(<Router history={hist}>
    <Switch>
        <Route path="/signin" component={LoginPage} />
        <Route path="/myvehicles" component={MyVehicles} />
        <Route path="/api" component={Api} />
        <Route path="/admin" component={Admin} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms" component={Terms} />
        <Route path="/faq" component={FAQ} />
        <Route path="/" component={Homepage} />
    </Switch>
</Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
