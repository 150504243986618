import React, {useEffect} from "react";
import GridContainer from "../components/Grid/GridContainer";
import {Grid, makeStyles} from "@material-ui/core";
import Container from "../components/CustomContainer/Container";
import * as firebase from 'firebase';
import 'firebase/auth';
import firebaseConfig from '../firebase';

const styles = {
    container: {
        backgroundColor: '#ccc',
        marginLeft: '10%',
        marginRight: '10%',
    }
};

const useStyles = makeStyles(styles);

// const firebaseApp = firebase.initializeApp(firebaseConfig);
// "VbFDpfHwgohIth7VFpghE31gANX2"
function Admin() {
    useEffect(() => {
        // const firebase = firebaseApp.auth();
        const getuser = firebase.functions().httpsCallable('adminGetUser');
        const updateuser = firebase.functions().httpsCallable('adminUpdateUser');
        getuser({
            password: 'Kendall Jordan a3z58DCS=JE3;7{Mrq3qrcc;FgW&3VH/4"{BW?Uc-UcV<[6tW=]2Gnr-A3}+Cahx',
            email: 'derekalvey1@yahoo.com'
        }).then(res => console.log(res))

        // updateuser({
        //     password: 'Kendall Jordan a3z58DCS=JE3;7{Mrq3qrcc;FgW&3VH/4"{BW?Uc-UcV<[6tW=]2Gnr-A3}+Cahx',
        //     uid: '9iZAgYdozoeMdm4Ul2K2YcPc8Nn2'
        // }).then(res => console.log(res))


    });

    const classes = useStyles();
    return (
        <Container>
            <div className={classes.container}>
                <GridContainer spacing={1}>
                    <Grid item md={6} lg={4} xs={6}>
                        <p>email</p>
                    </Grid>
                    <Grid item md={6} lg={4} xs={6}>
                        <input type="text" />
                    </Grid>
                </GridContainer>
            </div>
        </Container>

    )
}

export default Admin;
