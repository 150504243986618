import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Card from "../../../components/Card/Card.js";

import styles from "../../../assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import MobileCarouselItem from "./MobileCarouselItem.js";
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function MobileCarousel({vehicleHistory, action, currentVehicle}) {
    const classes = useStyles();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        focusOnSelect: true,
        vertical: true,
        afterChange: current => action(current)
    };
    return (
            <div className={classes.container}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6} lg={6} className={classes.marginAuto}>
                        <Card carousel>
                            <Carousel {...settings}>
                                {Object.keys(vehicleHistory).map((item) =>
                                    <MobileCarouselItem
                                        data={vehicleHistory[item]}
                                        selected={parseInt(currentVehicle) === parseInt(item)}
                                    />
                                )}
                            </Carousel>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
    );
}
