const firebaseConfig = {
    apiKey: "AIzaSyAz7GdPytOBmnyIEijOItwdUMnn-y68CGY",
    authDomain: "orto-253e0.firebaseapp.com",
    databaseURL: "https://orto-253e0.firebaseio.com",
    projectId: "orto-253e0",
    storageBucket: "orto-253e0.appspot.com",
    messagingSenderId: "983972205098",
    appId: "1:983972205098:web:32679dcb3c829a50cd6953"
};

export default firebaseConfig;
