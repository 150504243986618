/*eslint-disable*/
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {useStores} from "../../hooks/use-store";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {withRouter} from 'react-router-dom';

// @material-ui/icons
import { AccountCircle, DirectionsCar, DeveloperMode, CloudDownload } from "@material-ui/icons";

// core components
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

function HeaderLinks({history}) {
  const classes = useStyles();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

      useEffect(() => {
          if (AuthStore.getUser()) {
              setIsLoggedIn(true);
          }
          else {
              setIsLoggedIn(false);
          }
      });


    const { AuthStore } = useStores();

  return (
    <List className={classes.list}>

        {isLoggedIn ?
            <ListItem className={classes.listItem}>
                <Button
                    to="/myvehicles"
                    color="transparent"
                    className={classes.navLink}
                    component={Link}
                >
                    <DirectionsCar className={classes.icons} />My Vehicles
                </Button>
            </ListItem>
            : <ListItem className={classes.listItem}>
                <Button
                    component={Link}
                    to="/signin"
                    color="transparent"
                    className={classes.navLink}
                >
                    <AccountCircle className={classes.icons} />Login
                </Button>
            </ListItem>
        }

        <ListItem className={classes.listItem}>
                <Button
                    color="transparent"
                    className={classes.navLink}
                    component={Link}
                    to="/api"
                >
                <DeveloperMode className={classes.icons} />API Access
            </Button>
        </ListItem>



        <ListItem className={classes.listItem}>
            {isLoggedIn ? <Button
                    className={classes.registerNavLink}
                    onClick={e => {
                        AuthStore.logOut();
                        history.replace('/signin');
                    }}
                    color="primary"
                    round
                >
                    Sign Out
                </Button>
                :
                <Button
                    className={classes.registerNavLink}
                    onClick={e => e.preventDefault()}
                    color="rose"
                    component={Link}
                    to="/register"
                    round
                >
                    Register
                </Button>
            }
        </ListItem>
    </List>
  );
}

export default withRouter(HeaderLinks);
