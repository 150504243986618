import { observable, action, decorate } from 'mobx';
import * as firebase from 'firebase';
import 'firebase/auth';
import firebaseConfig from '../firebase';

// class Firebase {
//     constructor() {
//         app.initializeApp(firebaseConfig);
//         this.auth = app.auth();
//     }
//
//     login(email, password) {
//         return this.auth.signInWithEmailAndPassword(email, password)
//     }
// }

import CommonStore from './CommonStore';

class AuthStore {

    constructor() {
        this.email = '';
        this.password = '';
        this.token = '';
        this.firebaseApp = firebase.initializeApp(firebaseConfig);
        this.firebaseAppAuth = this.firebaseApp.auth();

        this.vehicleHistory = [];

        this.Common = CommonStore;
    }

    setField(event) {
        const { target: { name, value } } = event;
        this[name] = value;
    }

    login = async (history) => {
        this.Common.toggleLoadingSpinner();
        this.firebaseAppAuth.signInWithEmailAndPassword(this.email, this.password)
            .then(async (user) => {

                try {
                    console.log(user.user);
                    const getuser = firebase.functions().httpsCallable('getuserdata');
                    const userdata = await getuser();
                    // const getuserhistory = firebase.functions().httpsCallable('getuserhistory');
                    // const vehicleHistory = await getuserhistory();
                    // this.vehicleHistory = vehicleHistory.data.data;

                        history.push("/myvehicles");
                }
                catch(error) {
                    this.Common.toggleAlert(true, error.message);
                }

            })
            .catch(error => this.Common.toggleAlert(true, error.message))
            .finally(() => this.Common.toggleLoadingSpinner())
    };

    getUserHistory = () => {
        const userHistory = firebase.functions().httpsCallable('getuserhistory');
        return userHistory();
    };

    getUser = () => {
        return this.firebaseAppAuth.currentUser
    };

    logOut = () => {
        return this.firebaseAppAuth.signOut();
    }


}
decorate(AuthStore, {
    setField: action,
    login: action,
    getuserhistory: action,
    getUser: action,
    logOut: action,
    password: observable,
    email: observable,
    token: observable,
    vehicleHistory: observable,
});


export default new AuthStore();
