import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import NavPills from "../../../../components/NavPills/NavPills.js";

//Icons
import List from "@material-ui/icons/List";
import History from "@material-ui/icons/History";
import Timeline from "@material-ui/icons/Timeline";
import Notes from "@material-ui/icons/Notes";

import styles from "../../../../assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";
import Paper from "@material-ui/core/Paper";

import Overview from './Overview.js';
import VehicleHistory from './VehicleHistory.js';
import CostsValue from './CostsValue.js';

const useStyles = makeStyles(styles);

const VehiclePanel = ({data, id}) => {
    const classes = useStyles();
    const {experian, alpr, vehicle: {vinaudit}, vinlite} = data;
    console.log(vinaudit);
    console.log(experian,alpr);
    return (
        <div className={classes.container}>
        <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
            <NavPills
                key={id}
                color="rose"
                horizontal={{
                    tabsGrid: { xs: 12, sm: 4, md: 4, lg: 4 },
                    contentGrid: { xs: 12, sm: 8, md: 8, lg: 8 }
                }}
                tabs={[
                    {
                        tabButton: "Overview",
                        tabIcon: List,
                        tabContent: (
                            <Overview spec={vinaudit.spec.attributes} experian={experian} />
                        )
                    },
                    {
                        tabButton: "Recalls & History",
                        tabIcon: History,
                        tabContent: (
                            <VehicleHistory data={vinlite} />
                        )
                    },
                    {
                        tabButton: "Costs & Value",
                        tabIcon: Timeline,
                        tabContent: (
                           <CostsValue value={vinaudit.value} cost={vinaudit.cost} />
                        )
                    },
                    {
                        tabButton: "My Notes",
                        tabIcon: Notes,
                        tabContent: (
                            <span>
                        <p>
                          Efficiently unleash cross-media information without
                          cross-media value. Quickly maximize timely
                          deliverables for real-time schemas.
                        </p>
                        <br />
                        <p>
                          Dramatically maintain clicks-and-mortar solutions
                          without functional solutions. Dramatically visualize
                          customer directed convergence without revolutionary
                          ROI. Collaboratively administrate empowered markets
                          via plug-and-play networks. Dynamically procrastinate
                          B2C users after installed base benefits.
                        </p>
                      </span>
                        )
                    }
                ]}
            />
        </GridItem>
        </GridContainer>
        </div>
    )
};

export default VehiclePanel;
