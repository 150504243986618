import React, {useEffect} from "react";
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";

// core components
import Header from "../components/Header/Header.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import Footer from "../components/Footer/Footer.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import Button from "../components/CustomButtons/Button.js";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardFooter from "../components/Card/CardFooter.js";
import CustomInput from "../components/CustomInput/CustomInput.js";
import Container from "../components/CustomContainer/Container"

import styles from "../assets/jss/material-kit-react/views/loginPage.js";

import image from "../assets/img/header-background.jpg";
import {useStores} from "../hooks/use-store";

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(styles);

const LoginPage = (props) => {
    const classes = useStyles();
    const { ...rest } = props;
    const { AuthStore } = useStores();

    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

    setTimeout(function() {
        setCardAnimation("");
    }, 700);


    let history = useHistory();


    useEffect(() => {
       if (AuthStore.getUser()) {
           history.replace('/myvehicles');
       }
    });


    return (
        <Container>
            <Header
                absolute
                color="transparent"
                brand="Orto"
                rightLinks={<HeaderLinks />}
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                            <Card className={classes[cardAnimaton]}>
                                <form className={classes.form}>
                                    <CardHeader color="primary" className={classes.cardHeader}>
                                        <h3>Login</h3>

                                    </CardHeader>
                                    <p className={classes.divider}>Need an account? <Link to="/register">Register</Link></p>
                                    <CardBody>
                                        <CustomInput
                                            labelText="Email..."
                                            id="email"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                type: "email",
                                                name: "email",
                                                onChange: (e => AuthStore.setField(e)),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Email className={classes.inputIconsColor} />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <CustomInput
                                            labelText="Password..."
                                            id="password"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            onChange={e => AuthStore.setField(e)}
                                            inputProps={{
                                                type: "password",
                                                name: "password",
                                                onChange: (e => AuthStore.setField(e)),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Lock className={classes.inputIconsColor} />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </CardBody>
                                    <CardFooter className={classes.cardFooter}>
                                        <Button color="primary"
                                                size="lg"
                                                type="button"
                                                onClick={e => AuthStore.login(history)}>
                                            Sign In
                                        </Button>
                                    </CardFooter>
                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>

                <Footer whiteFont />
            </div>
        </Container>
    );
};

export default observer(LoginPage);
