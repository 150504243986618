/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CheckCircle from "@material-ui/icons/CheckCircle";


import styles from "../../assets/jss/material-kit-react/components/vehicleListItemStyle.js";

const useStyles = makeStyles(styles);

export default function VehicleListItem({data, action, selected}) {
    const classes = useStyles();

    const { alpr, vehicle: { thumb, vinaudit: {spec }} } = data;

    return (
        <div className={classes.container} onClick={action}>
            <Paper elevation={selected ? 9 : 2} className={classNames(classes.paper, selected ? classes.selected : null)}>
                <div className={classes.flexWrapper}>
                    {selected ? <CheckCircle className={classes.checkColor} /> : null}
                    <img src={thumb ? thumb : require('../../assets/img/default_car.png')} />
                    <div className={classes.titleWrapper}>
                        <h3 className={classNames(classes.defaultFontStyle,classes.make)}>{spec.attributes.Year} {spec.attributes.Make}</h3>
                        <h4 className={classNames(classes.defaultFontStyle,classes.model)}>{spec.attributes.Model}</h4>
                        {alpr ? <h4 className={classNames(classes.defaultFontStyle,classes.model)}>{alpr[0].plate}</h4> : null }
                    </div>
                </div>
            </Paper>
        </div>
    )
}
