/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
// core components
import styles from "../../../assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";

const useStyles = makeStyles(styles);

export default function SectionDownload() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={12} sm={12} md={10} justify="center">
            <h2>Orto | The App</h2>
            <h3>Automatic License Plate Recognition</h3>
            <h4>
              Orto Vehicle History lets you take a photo of a car, truck or motorcycle and using ALPR and a
              variety of public data sources gives you accurate information about the specification, market value,
              title and owner history.
            </h4>
              <br />
            <Button
                color="primary"
                size="lg"
                href="http://onelink.to/c9pf63"
                target="_blank"
            >
              Free Download
            </Button>
          </GridItem>

        </GridContainer>
        <br />
        <br />

        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={6} sm={6} md={3}>
            <img src={require('../../../assets/img/screenshots/challenger.png')} width="100%" />
          </GridItem>
          <GridItem xs={6} sm={6} md={3}>
            <img src={require('../../../assets/img/screenshots/challenger-details.png')} width="100%" />
          </GridItem>
        </GridContainer>
          <br /><br />
        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>For Business</h2>
            <h4>
              Parking enforcement, commercial property security, dealership management or fleet inventory control, Orto
              gives you easy, affordable access to technologies usually reserved for large enterprise and law enforcement.
            </h4>
          </GridItem>
        </GridContainer>

          <GridContainer className={classes.textCenter} justify="center">
          {/*<GridItem xs={12} sm={8} md={6}>*/}
          {/*  <Button*/}
          {/*    color="rose"*/}
          {/*    size="lg"*/}
          {/*    href="https://www.creative-tim.com/product/material-kit-pro-react?ref=mkr-download-section"*/}
          {/*    target="_blank"*/}
          {/*  >*/}
          {/*    More About Orto*/}
          {/*  </Button>*/}
          {/*</GridItem>*/}
        </GridContainer>
<br /><br />
        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={6} sm={3} md={2}>
            <img src={require('../../../assets/img/screenshots/screenshot1.png')} width="100%" />
          </GridItem>
          <GridItem xs={6} sm={3} md={2}>
            <img src={require('../../../assets/img/screenshots/screenshot3.png')} width="100%" />
          </GridItem>
          <GridItem xs={5} sm={3} md={2}>
            <img src={require('../../../assets/img/screenshots/screenshot2.png')} width="100%" />
          </GridItem>
        </GridContainer>
        <br />
        <br />

        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>Data services API</h2>
            <h4>
              Want access to our data services? Our comprehensive License Plate Recognition and reverse License Plate to VIN API is available at <a target="_blank" href="https://rapidapi.com/user/mail-x4ODT3u-t">Rapid API</a>
            </h4>
          </GridItem>
        </GridContainer>

      </div>
    </div>
  );
}
