import React from "react";
import { observer } from 'mobx-react';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import Footer from "../components/Footer/Footer.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import Card from "../components/Card/Card.js";
import Container from "../components/CustomContainer/Container"

import styles from "../assets/jss/material-kit-react/views/loginPage.js";

import image from "../assets/img/header-background.jpg";

import { useHistory } from 'react-router-dom';
import classNames from "classnames";
import SectionDownload from "./home/Sections/SectionDownload";

const useStyles = makeStyles(styles);

const FAQ = (props) => {
    const classes = useStyles();
    const { ...rest } = props;

    return (
        <Container>
            <Header
                absolute
                color="transparent"
                brand="Orto"
                rightLinks={<HeaderLinks />}
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <div className={classes.wrapper}>
                        <GridContainer className={classes.textCenter} justify="center">
                            <GridItem>
                                <h3>Contact and Support</h3>
                                <p>Email: <a href="mailto:mail@orto.mobi">mail@orto.mobi</a></p>
                                <p>Address: <br />
                                    Suite 3851 <br />1968 S. Coast Highway<br /> Laguna Beach<br /> CA 92651
                                </p>
                                <h3>FAQ and Help</h3> <p>
                                    I'm not a big company, I'm just one guy running this in my spare time around a full time job. While I do try and
                                reply to all emails, I get so many questions it sometimes takes time. So I thought I'd put together
                                some frequent questions to help y'all out.
                                </p>

                                <div className={classes.section}>

                                    <h4>Restoring your Orto Subscription</h4>

                                    <p>
                                        If you previously purchased a <strong>monthly</strong>, <strong>6 monthly</strong> or <strong>annual subscription</strong> and either
                                        lost your account, didn't setup a username and password or just aren't seeing the premium version
                                        you can login to the app, go to the <strong>Account</strong> screen and if you have an active subscription you can
                                        hit <strong>Restore Subscription</strong> to reactivate it.
                                    </p>
                                    <div className={classes.imageWrapper}>
                                    <img style={{alignSelf:'center'}} width="90%" src={require('../assets/img/faq/restore_subscription.jpg')} />
                                    </div>

                                </div>


                                <div className={classes.section}>

                                <h4>Cancelling Orto Subscription</h4>
                                    <p>
                                If you've signed up for one of the <strong>monthly/6 monthly</strong> or <strong>annual subscriptions</strong> and would like to cancel,
                                or get a refund, you can do this through Google Play.
                                    </p>
                                <p>Open the Play Store app, and click the menu in the top left and select Subscriptions</p>
                                <div className={classes.imageWrapper}>
                                <img style={{alignSelf:'center'}} width="90%" src={require('../assets/img/faq/google_play_1.jpg')} />
                                <div className={classes.divider}></div>
                                <img style={{alignSelf:'center'}} width="90%" src={require('../assets/img/faq/google_play_2.jpg')} />
                                </div>
                                </div>

                                <div className={classes.section}>
                                <h4>InfoTracer Owner Reports<br />(cancellation and queries)</h4>
                                <p>
                                    If you purchased a <strong>vehicle owner report</strong>, or any other product through InfoTracer you can contact
                                them directly. They'll be happy to provide refunds, changes and answer any questions. InfoTracer isn't
                                my company and I just provide a link to the website in the app and cannot process refunds myself.
                                </p>
                                <p>
                                InfoTracer contact information:<br />
                                    <a href="mailto:support@infotracer.com">support@infotracer.com</a><br />
                                    Phone: (800) 791-1427<br />
                                    <a href="http://www.infotracer.com" target="_blank">www.infotracer.com</a>
                                </p>
                                </div>

                                <div className={classes.section}>
                                    <h4>VinCheckup Vehicle History Reports</h4>

                                    <p>
                                        If you purchased a <strong>VinCheckup vehicle history report</strong>, or any other product through the <strong>VinCheckup</strong> website you can contact
                                        them directly. They'll be happy to provide refunds, changes and answer any questions.</p>

                                <p>
                                    <a href="https://www.vincheckup.com/support" target="_blank">Click for VinCheckup contact information</a>
                                </p>

                                    <h3>Frequently Asked Questions</h3>

                                    <h4>Why does my license plate not return any information?</h4>
                                    <p>Around 80% of searches return some sort of data, I gather information from a whole bunch
                                    of public databases, however these sources don't always have 100% coverage. If
                                    the vehicle has an expired title, has recently changed ownership or is an older vehicle (usually pre-1981)
                                    their is unlikely to be any data available digitally and you should contact your local DMV.</p>
                                    <p>Generally, newer vehicles that have a current title have the highest success rate.</p>

                                    <h4>I need to report a crime or find a missing person</h4>
                                    <p>If you have an urgent requirement to find the owner of a vehicle, and someone is at risk
                                        or their life is in danger you should contact the police immediately. Orto is not a government
                                        agency, and I cannot help with criminal matters.</p>

                                    <h4>The information provided isn't correct</h4>
                                    <p>I don't manage the vehicle date myself, it's sourced from various government and private
                                        agencies. They are responsible for any inaccuracies and it's not something I can amend, the
                                        best thing to do is reach out to your local DMV to clarify any anomalies.</p>

                                    <h4>Changes and improvement requests</h4>
                                    <p>If there's a feature you'd like to see in the app, or something annoying that you'd like to see changed please do
                                    drop me a line and I'll always try and incorporate requests in the next release.</p>

                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>

                <Footer whiteFont />
            </div>
        </Container>
    );
};

export default observer(FAQ);
