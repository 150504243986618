import { observable, action, decorate } from 'mobx';

class CommonStore {
    alert = {
            visible: false,
            message: 'this is a message'
        };

    loadingSpinnerVisible = false;

    timeout = null;

    toggleAlert = (visible = !this.alert.visible, message) => {
        clearTimeout(this.timeout);
        this.alert = {
            visible,
            message
        };

        if (this.alert.visible) {
            setTimeout(() => {
                this.alert.visible = false
            }, 5000)
        }
    };

    toggleLoadingSpinner = () => {
        this.loadingSpinnerVisible = !this.loadingSpinnerVisible;
    }
}

decorate(CommonStore, {
    alert: observable,
    loadingSpinnerVisible: observable,
    toggleAlert: action,
    toggleLoadingSpinner: action,
});

export default new CommonStore();
