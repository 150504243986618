import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
    headingTitle: {
        fontWeight: 'bold',
    }
});

function CostsValue({cost, value}) {

    const classes = useStyles();

    const {depreciation_cost, fees_cost, fuel_cost, insurance_cost, maintenance_cost, repairs_cost, total_cost} = cost;

    if (!cost) {
        return (
            <p>No data</p>
        )
    }

    return (
        <div className={classes.container}>
            <h2>Ownership Costs</h2>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align="left" className={classes.headingTitle}>Year</TableCell>
                    <TableCell align="center" className={classes.headingTitle}>1</TableCell>
                    <TableCell align="center" className={classes.headingTitle}>2</TableCell>
                    <TableCell align="center" className={classes.headingTitle}>3</TableCell>
                    <TableCell align="center" className={classes.headingTitle}>4</TableCell>
                    <TableCell align="center" className={classes.headingTitle}>5</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell component="th" align="left">Depreciation Cost</TableCell>
                        {Object.keys(depreciation_cost).map(column => (
                            <TableCell align="center" key={column}>${depreciation_cost[column]}</TableCell>
                        ))}
                </TableRow>
                <TableRow>
                    <TableCell component="th" align="left">Fees</TableCell>
                    {Object.keys(fees_cost).map(column => (
                        <TableCell align="center" key={column}>${fees_cost[column]}</TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell component="th" align="left">Fuel</TableCell>
                    {Object.keys(fuel_cost).map(column => (
                        <TableCell align="center" key={column}>${fuel_cost[column]}</TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell component="th" align="left">Insurance</TableCell>
                    {Object.keys(insurance_cost).map(column => (
                        <TableCell align="center" key={column}>${insurance_cost[column]}</TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell component="th" align="left">Maintenance</TableCell>
                    {Object.keys(maintenance_cost).map(column => (
                        <TableCell align="center" key={column}>${maintenance_cost[column]}</TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell component="th" align="left">Repairs</TableCell>
                    {Object.keys(repairs_cost).map(column => (
                        <TableCell align="center" key={column}>${repairs_cost[column]}</TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell component="th" align="left">Total Cost</TableCell>
                    {Object.keys(total_cost).map(column => (
                        <TableCell align="center" key={column}>${total_cost[column]}</TableCell>
                    ))}
                </TableRow>
            </TableBody>
        </Table>
        </div>
    )
}

export default CostsValue;
