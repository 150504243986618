import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import spinner from '../../assets/img/loading-spinner.gif';

const useStyles = makeStyles({

   wrapper: {
       position: 'fixed',
       top: 0,
       right: 0,
       bottom: 0,
       left: 0,
       backgroundColor: 'rgba(0, 0, 0, 0.7)',
       height: '100%',
       width: '100%',
       zIndex: 1100,
       alignItems: 'center',
   },
    text: {
       color: 'white',
        textAlign: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        display: 'flex',
    }

});

const LoadingSpinner = () => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <h3 className={classes.text}>Loading...</h3>
        </div>
    )
};
export default LoadingSpinner;
