import React, {useEffect, useState} from "react";
import {useStores} from "../../hooks/use-store";
import { withRouter } from 'react-router-dom';
import {observer} from "mobx-react";
import {makeStyles, Grid} from "@material-ui/core";
import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';

import HeaderLinks from "../../components/Header/HeaderLinks";
import Header from "../../components/Header/Header";
import Container from "../../components/CustomContainer/Container"
import Footer from "../../components/Footer/Footer";
import classNames from "classnames";
import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import VehiclePanel from './components/VehiclePanel/VehiclePanel'
import VehicleListItem from "../../components/VehicleListItem/VehicleListItem";
import MobileCarousel from './components/MobileCarousel.js';
import styles from "../../assets/jss/material-kit-react/views/landingPage.js";

const useStyles = makeStyles(styles);

function MyVehicles({history, ...rest}) {
    const classes = useStyles();

    const { AuthStore, CommonStore } = useStores();

    const [vehicleHistory, setVehicleHistory] = useState([]);

    const [currentVehicle, setCurrentVehicle] = useState(0);

    useEffect(() => {
        if (!AuthStore.getUser()) {
            history.push('/signin');
            return
        }

        if (vehicleHistory.length <= 0) {
            CommonStore.toggleLoadingSpinner();
            AuthStore.getUserHistory()
                .then((response) => {
                    setVehicleHistory(response.data.data);
                    console.log(vehicleHistory);
                })
                .catch((error) => {
                    history.push('/signin');
                })
                .finally(() => CommonStore.toggleLoadingSpinner());
        }
    });

    return (
        <Container>
            <Header
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 100,
                    color: "white"
                }}
                brand="Orto"
                rightLinks={<HeaderLinks />}
                {...rest}
            />

            <Parallax filter small image={require("../../assets/img/header-background.jpg")}>
                <div className={classes.container + ' ' + classes.textCenter}>
                    <GridContainer>
                        <GridItem>
                            <h2 className={classes.textCenter}>Your Vehicles</h2>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>


            {/*<Hidden smUp></Hidden>*/}
                <MobileCarousel vehicleHistory={vehicleHistory} action={setCurrentVehicle} currentVehicle={currentVehicle} />

            <div className={classNames(classes.main, classes.mainRaised)}>
                <GridContainer spacing={0}>
                    <Hidden smDown>
                        <GridItem item xs md={3} lg={3}>
                            <div className={classNames(classes.container, classes.scrollableList)}>
                                {Object.keys(vehicleHistory).map((item) =>
                                    <VehicleListItem
                                        key={item}
                                        action={() => setCurrentVehicle(item)}
                                        selected={parseInt(currentVehicle) === parseInt(item)}
                                        data={vehicleHistory[item]} />
                                )}
                            </div>
                    </GridItem>
                    </Hidden>
                    <GridItem md={9} lg={9} xs={12}>
                        {vehicleHistory[currentVehicle]?
                            <VehiclePanel data={vehicleHistory[currentVehicle]} id={currentVehicle} />
                         : null}
                    </GridItem>
                </GridContainer>
            </div>
            <Footer />
        </Container>
    )
}

export default observer(withWidth()(withRouter(MyVehicles)));
