import { container, title } from "../../../material-kit-react.js";

const pillsStyle = {
  section: {
    padding: "70px 0"
  },
  container: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  paper: {
    marginTop: 10,
    paddingLeft: 15,
  },
  bold: {
    fontWeight: "bold",
  },
  year: {
    marginBottom: 0,
  },
  makeModel: {
    marginTop: 0,
  }
};

export default pillsStyle;
