import React from "react";
import logo from '../assets/img/orto/logoDark.png';
import './landing.css';
export default function Homepage(props) {
    return (
        <div className="landing-container">
            <img src={logo} alt="Orto Logo" width="200" />
            <h2>Orto is Closing</h2>
            <p>With a heavy heart, I've had to make the incredibly tough decision to close Orto.</p>
            <p>
                A heartfelt thank you to everyone who has used the app for the past 4+ years.
                Engaging with all the AAA drivers, law enforcement officers, car dealers, buyers, and automotive community who made it so popular has been immensely rewarding.
                The feedback, reviews and appreciation from many have been a source of immense satisfaction for me,
                and in over two decades of making websites and apps, Orto stands out as the project I hold closest to my heart.
                Its conclusion marks the end of a truly fulfilling chapter.
            </p>

            <p>Thank you</p>

            <h3>Refunds and Cancellations</h3>
            <p>
                If you currently hold a subscription, it's imperative to navigate to your <strong>Google Play</strong> or <strong>App Store</strong> app and select "Subscriptions". Here, you can cancel your subscription and seek refunds for any 1, 6, or 12-month commitments you've made.
            </p>

            <p>
                Orto will soon be delisted from both the App Store and Google Play. All associated services will cease by November. If you have the app installed, it will become non-functional, and we recommend its removal.
            </p>
        </div>
    );

}
