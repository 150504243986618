/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { makeStyles } from "@material-ui/core/styles";


import styles from "../../../assets/jss/material-kit-react/components/MobileCarouselItemStyle.js";
import {Paper} from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function MobileCarouselItem({data, selected = false}) {
    const classes = useStyles();

    const { vehicle: { thumb, vinaudit: {spec }} } = data;
    //
    // console.log(spec);
    return (
        <Paper elevation={selected ? 9 : 2} className={classes.paper}>

        <div className={classes.container}>
            <div className={classes.flexWrapper}>
                 <img src={thumb ? thumb : require('../../../assets/img/default_car.png')} align="center" style={{display: 'inline'}}/>
                    <div className={classes.titleWrapper}>
                        <h3 className={classNames(classes.defaultFontStyle,classes.make)}>{spec.attributes.Year} {spec.attributes.Make}</h3>
                        <h4 className={classNames(classes.defaultFontStyle,classes.model)}>{spec.attributes.Model}</h4>
                    </div>
                </div>
        </div>
        </Paper>
    )
}
