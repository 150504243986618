import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
    headingTitle: {
        fontWeight: 'bold',
    }
});

function VehicleHistory({data}) {
    const classes = useStyles();

    if (!data) {
        return (
            <p>No data</p>
        )
    }

    const { vinLiteReport: {vehicleHistory}} = data;


    return (
        <div className={classes.container}>
            <h2>Recalls and History</h2>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell align="left" className={classes.headingTitle}>Date</TableCell>
                    <TableCell align="left" className={classes.headingTitle}>Details</TableCell>
                    <TableCell align="left" className={classes.headingTitle}>Source</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(vehicleHistory).map(row => (
                    <TableRow key={row.name} key={row}>
                        <TableCell component="th" align="left">{vehicleHistory[row].date}</TableCell>
                        <TableCell align="left">{vehicleHistory[row].details}</TableCell>
                        <TableCell align="left">{vehicleHistory[row].source}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </div>
    )
}

export default VehicleHistory;
