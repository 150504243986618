import {container, title} from "../../material-kit-react";

const textPageStyles = {
    container: {
        paddingTop: 10,
        zIndex: "12",
        color: "#000",
        ...container,
        maxWidth: "980px",
    },
    title: {
        ...title,
        fontWeight: "200",
        display: "inline-block",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        color: "#000",
        textDecoration: "none"
    },
    subtitle: {
        fontSize: "1.313rem",
        maxWidth: "500px",
        margin: "10px auto 0"
    },
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
    },
    mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    scrollableList: {
        overflow: 'auto',
        maxHeight: 620,
    }
};

export default textPageStyles;
