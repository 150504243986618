import {
    defaultFont,
    primaryColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor
} from "../../material-kit-react.js";

const vehicleListItemStyle = {
    defaultFontStyle: {
        ...defaultFont,
        fontSize: "14px",
        color: "black",
        textAlign: "center",
    },
    year: {
        marginBottom: 0,
    },
    container: {
      display: "block",
        textAlign: "center",
        position: 'relative',
    },

    flexWrapper: {
        flexDirection: "row",
    },

    make: {
        marginTop: 0,
        marginBottom: 0,
    },
    model: {
        marginTop: 0,
        marginBottom: 0,
    },
    titleWrapper: {
        marginLeft: 10,
        alignItems: "left",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
    },
    title: {
        marginTop: 0,
    },
    paper: {
        marginBottom: 10,
        padding: 15,
    },
    textCenter: {
        textAlign: "center",
    },
    selected: {
        borderColor: "blue",
        borderWidth: 1,
    },
    checkColor: {
        color: '#228B22',
        position: 'absolute',
        top: 10,
        right: 10,
    },
};

export default vehicleListItemStyle;
