import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import Button from "../../../../components/CustomButtons/Button";

const useStyles = makeStyles({
    container: {
        width: '100%',
        overflow: 'hidden',
    },
    headingTitle: {
        fontWeight: 'bold',
    },
    bold: {
        fontWeight: "bold",
    },
    year: {
        marginBottom: 0,
        lineHeight: 0.6,
    },
    makeModel: {
        marginTop: 0,
    },
    fullwidthButton: {
        width: '100%',
    },
    warningWrapper: {
        marginTop: 15,
        marginBottom: 15,
    }
});

function Overview({spec, experian}) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <GridContainer>
                <GridItem sm={12} md={12} lg={12}>
                        <div>
                            <h4 className={classes.year}>{spec.Year}</h4>
                            <h2 className={classes.makeModel}><span className={classes.bold}>{spec.Make}</span> {spec.Model}</h2>
                        </div>
                </GridItem>
            </GridContainer>

            <div className={classes.warningWrapper}>

                {experian ?
                <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={6} lg={6}>
                    {experian.accidentRecord ?
                        <Button color="danger" className={classes.fullwidthButton}>Accident Record Found</Button> : <Button color="success" className={classes.fullwidthButton}>No Accident Record</Button>}
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                    {experian.floodRecord ?
                        <Button color="danger" size="large" className={classes.fullwidthButton}>Flood Record Found</Button> : <Button color="success" className={classes.fullwidthButton}>No Flood Record</Button>}
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                    {experian.lemonRecord ?
                        <Button color="danger" className={classes.fullwidthButton}>Lemon Record Found</Button> : <Button color="success" className={classes.fullwidthButton}>No Lemon Record</Button>}
                </GridItem>

                <GridItem xs={12} sm={6} md={6} lg={6}>
                    {experian.singleOwner ?
                        <Button color="warning" className={classes.fullwidthButton}>Multiple Owners</Button> : <Button color="success" className={classes.fullwidthButton}>Single Owner</Button>}
                </GridItem>
                </GridContainer> : null}

                <GridContainer justify="center">
                <Table aria-label="Vehicle Specification">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" className={classes.headingTitle}>Feature</TableCell>
                            <TableCell align="left" className={classes.headingTitle}>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(spec).map(row => (
                            spec[row] ? <TableRow key={row.name} key={row}>
                                <TableCell component="th" align="left">{row}</TableCell>
                                <TableCell align="left">{spec[row]}</TableCell>
                            </TableRow> : null
                        ))}
                    </TableBody>
                </Table>

            </GridContainer>
            </div>
        </div>
    )
}

export default Overview;
